@import "../../../assets/styles/mainVariables";
@import "../../../assets/styles/fonts";

.partners-wrapper {
  width: $contentWidth;
  padding: 30px 0 60px ;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  .partners-list-wrapper {
    position: relative;
    width: 100%!important;

    .slick-slider {
      width: 100%!important;
      .slick-list {
        width: 100%!important;
        overflow: hidden;
        .slick-track {
          padding-top: 5px;
          display: flex;
          @media screen and (max-width: 400px){
            margin-right: 90px;
          }
        }
      }
    }
    .slick-slide .slick-active{
      width: 300px;
    }

    .partner-item {
      //width: 90%;
      //height: 100%;
      //position: relative;
      width: 160px;
      height: 100px;
      margin-right: 20px;
      display: flex;
      align-items: center;
      justify-content: center;

      .partner-content {
        width: 160px;
        height: 100px;
        display: flex;
        align-items: center;
        justify-content: center;
        //padding-top: 62.5%;
        //position: relative;
        img {
          width: 100%;
          //height: 100%;
          ////height: 100%;
          ////margin: auto;
          ////transition: all 0.3s;
          ////padding: 0 10px;
          //position: absolute;
          //top: 0;
          //left: 0;
          //object-fit: none;
        }
      }
    }
  }

  @media all and (max-width: 1050px) {
    padding: 30px 0 0 0;
    top: unset!important;
    width: 100%!important;
    border: unset!important;
    .partners-list-wrapper{
      width: 100%!important;
    }
    .partners-title {
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
      letter-spacing: 0;
      text-align: center;
      margin: 0 0 20px 0;
    }
    .next-btn, .prev-btn {
      display: none;
    }
  }
}