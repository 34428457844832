@import "../../../assets/styles/mainVariables";
@import "../../../assets/styles/fonts";

.homeService-wrapper{
  padding: 114px 0;
  width: $contentWidth;
  .homeService-title{
    color: #231F20;
    font-family: NotoArmenian;
    font-size: 36px;
    font-style: normal;
    font-weight: 600;
    line-height: 44px;
    letter-spacing: -0.72px;
  }
  .homeService-description{
    color: #696773;
    font-family: NotoArmenian;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px;
    margin-top: 20px;
  }
  .homeService-blocks{
    width: 100%;
    margin-top: 52px;
    gap: 32px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    .homeService-card{
      height: 352px;
      width: calc(100% / 3 - 32px);
      padding: 40px 40px;
      border-radius: 16px;
      background: #FFF;
      display: flex;
      flex-direction: column;
      box-shadow: 1em 0 1em 0 #DDDDDD, -1em 0 1em 0 #DDDDDD;
      .homeService-icon{
        width: 54px;
        height: 54px;
        img{
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
      .homeService-card-title{
        color: #231F20;
        font-family: NotoArmenian;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px;
        margin-top: 52px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
      }
      .homeService-card-description{
        color: #696773;
        font-family: NotoArmenian;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
        margin-top: 12px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
      }
      .homeService-card-more{
        margin-top: 24px;
        display: flex;
        align-items: center;
        color: #1A5AFF;
        font-family: NotoArmenian;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px;
        cursor: pointer;
        svg{
          width: 24px;
          height: 24px;
          margin-left: 8px;
          path{
            stroke:$brandColor;
          }
        }
      }
    }
    .homeService-more-block{
      height: 352px;
      width: calc(100% / 3 - 36px);
      background-color:$brandColor;
      padding: 50px 40px;
      border-radius: 16px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      color: #FFF;
      text-align: center;
      font-family: NotoArmenian;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 24px;
      span{
        margin-top: 24px;
      }
    }
  }

  @media screen and (max-width: 1050px){
    padding: 30px $mainPadding;
    .homeService-title{
      font-size: 26px;
    }
    .homeService-description{
      font-size: 16px;
    }
    .homeService-blocks{
      flex-direction: column;
      .homeService-card{
        width: 100%;
      }
      .homeService-more-block{
        width: 100%;
        height: 200px;
      }
    }
  }
}