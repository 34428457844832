@import "../../../../assets/styles/fonts";
@import "../../../../assets/styles/mainVariables";

.newsModal{
  width: 620px!important;
}
.news-modal-wrapper{
  width: 100%;
  .news-block-img{
    padding-top: 50%;
    position: relative;
    margin-top: 20px;
    img{
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      object-fit: cover;
    }
  }
  .news-block-info{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-top: 32px;
    .news-date{
      color: #1A5AFF;
      text-align: center;
      font-family: NotoArmenian;
      font-size: 20px;
      font-style: normal;
      font-weight: 400;
      line-height: 30px;
    }
    .news-block-title{
      color: #231F20;
      text-align: center;
      font-family: NotoArmenian;
      font-size: 24px;
      font-style: normal;
      font-weight: 600;
      line-height: 32px;
      margin-top: 12px;
    }
    .news-block-description{
      color: #696773;
      text-align: center;
      font-family: NotoArmenian;
      font-size: 20px;
      font-style: normal;
      font-weight: 400;
      line-height: 30px;
      margin-top: 8px;
    }
  }
}
@media screen and (max-width: 1050px){
  .newsModal{
    width: 100%;
  }
  .news-modal-wrapper{
    .news-block-img{
      padding-top: 78.7%;
    }
  }
}