@import "../../assets/styles/fonts";
@import "../../assets/styles/mainVariables";

.homepage-wrapper{
  overflow: hidden;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}