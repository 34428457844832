@import "../../assets/styles/mainVariables";
@import "../../assets/styles/fonts";

.blog-wrapper {
  //-webkit-user-select:none!important;
  //user-select: none!important;
  padding: 0 $mainPadding;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  .blog-header{
    width:$contentWidth;
    padding: 80px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    .subtitle{
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
      letter-spacing: 0;
      text-align: left;
      color:$brandColor;
    }
    .title{
      color: #231F20;
      text-align: center;
      font-family: NotoArmenian;
      font-size: 48px;
      font-style: normal;
      font-weight: 700;
      line-height: 60px;
      letter-spacing: -0.48px;
      margin-top: 16px;
    }
    .description{
      color: #696773;
      text-align: center;
      font-family: NotoArmenian;
      font-size: 20px;
      font-style: normal;
      font-weight: 400;
      line-height: 30px;
      margin-top: 20px;
    }
  }
  .last-news-wrapper{
    width:$contentWidth;
    margin-top: 80px;
    .last-news-title{
      color: #696773;
      font-family: NotoArmenian;
      font-size: 24px;
      font-style: normal;
      font-weight: 600;
      line-height: 32px;
      margin: 64px 0;
    }
    .last-news{
      width: 100%;
      height: 100%;
      display: flex;
      //align-items: baseline;
      justify-content: space-between;
      .first-news{
        width: calc(50% - 32px);
        height: 100%;
        .last-news-img{
          padding-top: 50%;
          position: relative;
          img{
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            object-fit: cover;
          }
        }
        .last-news-info{
          width: 100%;
          margin-top: 32px;
          display: flex;
          flex-direction: column;
          .date{
            color: #1A5AFF;
            font-family: NotoArmenian;
            font-size: 20px;
            font-style: normal;
            font-weight: 400;
            line-height: 30px;
          }
          .title-wrapper{
            width: 100%;
            display: flex;
            align-items: baseline;
            justify-content: space-between;
            margin-top: 12px;
            .title{
              color: #231F20;
              font-family: NotoArmenian;
              font-size: 24px;
              font-style: normal;
              font-weight: 600;
              line-height: 32px;
              width: 96%;
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-line-clamp: 2;
              -webkit-box-orient: vertical;
            }
            .share{
              cursor: pointer;
            }
          }
          .description{
            color: #696773;
            font-family: NotoArmenian;
            font-size: 20px;
            font-style: normal;
            font-weight: 400;
            line-height: 30px;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
            margin-top: 12px;
          }
        }
        &:hover{
          .last-news-info{
            .title-wrapper{
              .title{
                color:$brandColor;
              }
              .share{
                svg{
                  path{
                    stroke:$brandColor;
                  }
                }
              }
            }
          }
        }
      }
      .right-news-block{
        width: 50%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .other-news{
          width: 100%;
          display: flex;
          justify-content: space-between;
          .img-wrapper{
            width: 46%;
            .last-news-img{
              padding-top: 82.6%;
              position: relative;
              img{
                width: 100%;
                height: 100%;
                position: absolute;
                top: 0;
                left: 0;
                object-fit: cover;
              }
            }
          }
          .last-news-info{
            width: 48%;
            display: flex;
            flex-direction: column;
            .date{
              color: #1A5AFF;
              font-family: NotoArmenian;
              font-size: 20px;
              font-style: normal;
              font-weight: 400;
              line-height: 30px;
            }
            .title-wrapper{
              width: 100%;
              display: flex;
              align-items: baseline;
              justify-content: space-between;
              margin-top: 12px;
              .title{
                color: #231F20;
                font-family: NotoArmenian;
                font-size: 24px;
                font-style: normal;
                font-weight: 600;
                line-height: 32px;
                width: 96%;
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
              }
              .share{
                cursor: pointer;
              }
            }
            .description{
              color: #696773;
              font-family: NotoArmenian;
              font-size: 20px;
              font-style: normal;
              font-weight: 400;
              line-height: 30px;
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-line-clamp: 3;
              -webkit-box-orient: vertical;
              margin-top: 8px;
            }
          }
          &:hover{
            .last-news-info{
              .title-wrapper{
                .title{
                  color:$brandColor;
                }
                .share{
                  svg{
                    path{
                      stroke:$brandColor;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  .news-wrapper{
    width:$contentWidth;
    margin-top: 180px;
    padding-bottom: 60px;
    margin-bottom: 20px;
    .news-wrapper-title{
      color: #696773;
      font-family: NotoArmenian;
      font-size: 24px;
      font-style: normal;
      font-weight: 600;
      line-height: 32px;
      margin-bottom: 34px;
    }
    .news{
      display: flex;
      flex-wrap: wrap;
      .news-block{
        margin-right: 32px;
        &:hover{
          .news-block-info{
            .news-title-wrapper{
              .news-block-title{
                color:$brandColor;
              }
              .news-block-link{
                svg{
                  path{
                    stroke:$brandColor;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  .pagination{
    width:$contentWidth;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    margin-bottom: 20px;
    &.hide-prev{
      .ant-pagination-prev{
        display: none;
      }
    }
    &.hide-prev{
      .ant-pagination-next{
        display: none;
      }
    }
    .prev_btn{
      height: 40px;
      position: absolute;
      left: 0;
      display: flex;
      align-items: center;
      span{
        font-size: 14px;
        font-weight: 500;
        line-height: 24px;
        letter-spacing: 0;
        text-align: left;
        color: #475467;
      }
      svg{
        margin-right: 12px;
      }
    }
    .next_btn{
      height: 40px;
      position: absolute;
      right: 0;
      display: flex;
      align-items: center;
      span{
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: 0;
        text-align: left;
        color: #667085;
      }
      svg{
        margin-left: 12px;
      }
    }
    .ant-pagination-item{
      width: 40px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      border: unset;
      a{
        color: #475467;
      }
    }
    .ant-pagination-item-active{
      width: 40px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 8px;
      background:#F9FAFB;
      a{
        color: #1D2939;
      }
    }
  }
  .subscribe-wrapper{
    width:$contentWidth;
    padding: 140px 0 80px 0;
  }
  @media screen and (max-width: 1100px){
    .blog-header{
      padding: unset;
      margin-top: 60px;
      .title{
        color: #231F20;
        font-family: NotoArmenian;
        font-size: 36px;
        font-style: normal;
        font-weight: 700;
        line-height: 46px;
        letter-spacing: -0.36px;
        text-align: left;
      }
      .description{
        color: #696773;
        font-family: NotoArmenian;
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: 28px;
        text-align: left;
      }
    }
    .last-news-wrapper{
      margin-top: 56px;
      padding-bottom: 24px;
      border-bottom: 1px solid #EBEBEB;
      .last-news-title{
        font-size: 20px;
        font-weight: 600;
        line-height: 28px;
        letter-spacing: 0;
        text-align: left;
      }
      .last-news{
        flex-direction: column;
        .first-news{
          width: 100%;
          .last-news-img{
            padding-top: 79.3%;
          }
        }
        .right-news-block{
          width: 100%;
          .other-news{
            margin-top: 32px;
            width: 100%;
            flex-direction: column;
            .img-wrapper{
              width: 100%;
              .last-news-img{
                padding-top: 79.3%;
              }
            }
            .last-news-info{
              margin-top: 32px;
              width: 100%;
            }
          }
        }
      }
    }
    .news-wrapper{
      margin-top: 32px;
      .news{
        .news-block{
          margin-right: unset;
        }
      }
      .news-wrapper-title{
        font-size: 20px;
        margin-bottom: unset;
      }
    }
    .pagination{
      .prev_btn{
        border: 1px solid #D0D5DD;
        border-radius: 8px;
        width: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        svg{
          margin-right: unset;
        }
        span{
          display: none;
        }
      }
      .next_btn{
        border: 1px solid #D0D5DD;
        border-radius: 8px;
        width: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        svg{
          margin-left: unset;
        }
        span{
          display: none;
        }
      }
      .ant-pagination-simple-pager{
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 14px;
        margin-right: unset;
        input{
          margin-right: unset;
          padding: unset;
          border: unset;
        }
      }
    }
    .subscribe-wrapper{
      padding: 140px 0 0 0;
    }
  }
}