@import "../../../../assets/styles/fonts";
@import "../../../../assets/styles/mainVariables";

.serviceModal{
  width: 820px!important;
}
.service-modal-wrapper{
  width: 100%;
  .service-block-img{
    padding-top: 73.7%;
    position: relative;
    margin-top: 20px;
    img{
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      object-fit: cover;
    }
  }
  .service-block-info{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-top: 32px;
    .service-block-title{
      color: #231F20;
      text-align: center;
      font-family: NotoArmenian;
      font-size: 24px;
      font-style: normal;
      font-weight: 600;
      line-height: 32px;
      margin-top: 12px;
    }
    .service-block-description{
      color: #696773;
      text-align: center;
      font-family: NotoArmenian;
      font-size: 20px;
      font-style: normal;
      font-weight: 400;
      line-height: 30px;
      margin-top: 8px;
    }
  }
  .service-modal-input-wrapper{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 66px;
    .service-modal-input-title{
      color: #1A5AFF;
      text-align: center;
      font-family: NotoArmenian;
      font-size: 20px;
      font-style: normal;
      font-weight: 600;
      line-height: 32px;
      letter-spacing: -0.4px;
    }
    .service-modal-input-description{
      width: 70%;
      color: #696773;
      text-align: center;
      font-family: NotoArmenian;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px;
      margin-top: 20px;
    }
    .service-modal-inputs{
      margin-top: 40px;
      width: 80%;
      .custom-input-group{
        width: 100%;
        margin-bottom: 16px;
        input{
          border-radius: 4px;
          border: 1px solid #D0D5DD;
          padding-left: 16px;
          color: #696773;
          font-family: NotoArmenian;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 24px;
          margin-top: 8px;
        }
        label{
          color: #D0D5DD;
          font-family: NotoArmenian;
          font-size: 14px;
          font-style: normal;
          font-weight: 700;
          line-height: 24px;
        }
      }
      .sent-email{
        width: 100%;
        cursor: pointer;
        height: 56px;
        margin-top: 16px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 4px;
        background: #1A5AFF;
        color: #FFF;
        text-align: center;
        font-family: NotoArmenian;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px;
      }
    }
  }
}
@media screen and (max-width: 1050px){
  .serviceModal{
    width: 100%;
  }
  .service-modal-wrapper{
    .service-block-img{
      padding-top: 74%;
    }
    .service-modal-input-wrapper{
      .service-modal-input-description{
        width: 100%;
      }
      .service-modal-inputs{
        width: 100%;
      }
    }
  }
}