@import "../../../assets/styles/mainVariables";
@import "../../../assets/styles/fonts";


.homePackage-wrapper{
  padding: 100px 0;
  width:$contentWidth;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .homePackage-blocks{
    width: 48%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 24px;
    .background-responsive-img{
     opacity: 0;
      visibility: hidden;
      display: none;
    }
    .homePackage-card{
      width:calc(100% / 2 - 12px);
      background-color:$brandColor;
      border-radius: 16px;
      padding: 58px 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      &:nth-child(4){
        background-color: #EBEBEB;
        padding: 52px 42px;
        .homePackage-card-title{
          color: #696773;
        }
        .homePackage-icon{
          margin-top: 12px;
          width: 64px;
          height: 64px;
          img{
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
      }
      .homePackage-card-title{
        color: #FFF;
        text-align: center;
        font-family: NotoArmenian;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px;
      }
      .homePackage-icon{
        margin-top: 32px;
        width: 32px;
        height: 32px;
        img{
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }
  }
  .homePackage-info{
    width: 48%;
    position: relative;
    .homePackage-title{
      width: 502px;
      color: #231F20;
      font-family: NotoArmenian;
      font-size: 36px;
      font-style: normal;
      font-weight: 600;
      line-height: 48px;
      letter-spacing: -0.72px;
      margin-top: 20px;
    }
    .homePackage-description{
      color: #696773;
      font-family: NotoArmenian;
      font-size: 20px;
      font-style: normal;
      font-weight: 400;
      line-height: 32px;
      margin-top: 20px;
    }
    .background-img{
      position: absolute;
      right: -80px;
      transform: translate(0,-26%);
      z-index: -1;
    }
  }

  @media screen and (max-width: 1050px){
    flex-direction: column-reverse;
    padding: 44px $mainPadding;

    .homePackage-blocks{
      width: 100%;
      flex-direction: column;
      gap: unset;
      position: relative;
      .background-responsive-img{
        display: unset;
        width: 116px;
        height: 180px;
        opacity: unset;
        position: absolute;
        right: -60px;
        bottom: -180px;
        visibility: visible;
        img{
          width: 100%;
          height: 100%;
        }
      }
      .homePackage-card{
        width: 100%;
        margin-top: 20px;
      }
    }
    .homePackage-info{
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin-bottom: 34px;
      .background-img{
        display: none;
      }
      .homePackage-title{
        width: 100%;
        color: #231F20;
        text-align: center;
        font-family: NotoArmenian;
        font-size: 26px;
        font-style: normal;
        font-weight: 600;
        line-height: 38px;
        letter-spacing: -0.52px;
      }
      .homePackage-description{
        text-align: center;
        color: #696773;
        font-family: NotoArmenian;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
      }
    }
  }
}